import { combineReducers } from "redux";
import history from "utils/history";
import auth from "./auth";
import utils from "./utils";
import membership from "./membership";
import users from "./users";
import queue from "./queue";
import jobs from "./jobs";
import contractors from "./contractors";
import serviceLocations from "./serviceLocations";
import vendors from "./vendors";
import breadcrumb from "./breadcrumb";
import newEstimate from "./taskModal/newEstimate";
import invoices from "./invoices";
import invoicesBatches from "./invoicesBatches";
import activityTracking from "./activityTracking";
import payments from "./payments";
import checksPayment from "./checksPayment";
import app from "./app";
import batch from "./batch";
import estimates from "./estimates"
import reports from "./reports"
import membershipCall from "./membershipCall";
import voicecallMail from "./voicecallMail";
import allCallLogs from "./allCallLogs";
import email from "./email"

export const createReducer = (asyncReducers) =>
  combineReducers({
    app,
    breadcrumb,
    auth,
    history,
    utils,
    membership,
    membershipCall,
    voicecallMail,
    users,
    queue,
    jobs,
    contractors,
    serviceLocations,
    vendors,
    newEstimate,
    invoices,
    invoicesBatches,
    activityTracking,
    payments,
    batch,
    allCallLogs,
    checksPayment,
    estimates,
    reports,
    email,
    ...asyncReducers,
  });

export default combineReducers({
  app,
  breadcrumb,
  auth,
  history,
  utils,
  membership,
  membershipCall,
  voicecallMail,
  users,
  jobs,
  contractors,
  serviceLocations,
  vendors,
  queue,
  newEstimate,
  invoices,
  invoicesBatches,
  activityTracking,
  allCallLogs,
  payments,
  batch,
  checksPayment,
  estimates,
  email,
  reports
});
