import { SEARCH_EMAIL_LOADING, SEARCH_EMAIL_RESULTS, CHANGE_PAGE, CHANGE_PER_PAGE} from "redux/actions/emailAction";
  
  const initialState = {
    loading: true,
    search: "",
    results: [],
    filter: {
      qbeSent: false,
      qbeNotSent: false
    },
    meta: {
      current_page: 1,
      from: null,
      last_page: 1,
      per_page: 10,
      to: null,
      total: 0,
    },
  };
  export default function invoices(state = initialState, action = {}) {
    switch (action.type) {
      case SEARCH_EMAIL_RESULTS:
        return {
          ...state,
          results: action?.results?.result,
          meta: action?.results?.meta,
          loading: false,
        };
      case SEARCH_EMAIL_LOADING:
        return { ...state, loading: action?.loading };

      case CHANGE_PAGE:
        return {
          ...state,
          meta: {
            ...state.meta,
            current_page: action.page,
          },
        };
      case CHANGE_PER_PAGE: {
        return {
          ...state,
          meta: {
            ...state.meta,
            per_page: action.perPage,
          },
        };
      }
      default:
        return state;
    }
  }