import axios from "utils/axios";

const GET_EMAIL_RESULT = `${process.env.REACT_APP_API_DOMAIN}/api/v1/email/get-all-mails`;
const UPDATE_EMAIL_RESULT = `${process.env.REACT_APP_API_DOMAIN}/api/v1/email/update/status`;
const TASKS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/email/update/assigned_to`;
const GET_EMAIL_MESSAGE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/email/messages`;
const SEND_EMAIL_MESSAGE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/email/reply`;
const LOGIN_MICROSOFT = `${process.env.REACT_APP_API_DOMAIN}/api/v1/email/access-token-exists`;

export const getEmailRequest = (params) =>
  axios.get(GET_EMAIL_RESULT, { params });

export const updateEmailStatus = (id, data) =>
  axios.post(`${UPDATE_EMAIL_RESULT}/${id}`, data, {
    headers: {
      "Content-Type": "application/json", // Set the content type
    },
  });
;

export const changeTaskUserRequest = (id, data) =>
  axios.post(`${TASKS_ROUTE}/${id}`, data);

export const getEmailMessage = (id, data) =>
  axios.get(`${GET_EMAIL_MESSAGE}/${id}`, data);

export const sendEmailMessage = (id,payload) => {
  return axios.post(`${SEND_EMAIL_MESSAGE}/${id}`, payload);
};

export const loginMicrosoftRequest = (params) =>
  axios.get(LOGIN_MICROSOFT, { params });