const statuses = {
  COMPLETED: "#34bf49",
  DISPATCH: "#fbb034",
  ESTIMATE: "#0077c8",
  RESERVICE: "#00aeff",
  DUPLICATE: "#777777",
  CANCEL: "#e4002b",
  OPEN: "#01cd74",
  NEW: "#01cd74",
};

const statusList = [
  "OPEN",
  "ESTIMATE",
  "DISPATCH",
  "DUPLICATE",
  "RESERVICE",
  "CANCEL",
  "COMPLETED",
];
const statusListContractor = [
  "OPEN",
  "ESTIMATE",
  "DISPATCH",
  "DUPLICATE",
  "RESERVICE",
  "CANCEL",
  "COMPLETED",
];

export { statuses, statusList, statusListContractor };
