import {
  SEARCH_CONTRACTORS_LOADING,
  SEARCH_CONTRACTORS_RESULTS,
  SEARCH_CONTRACTORS,
  ACTIVE_CONTRACTORS_LOADING,
  ACTIVE_CONTRACTORS_RESULTS,
  INACTIVE_CONTRACTORS_LOADING,
  INACTIVE_CONTRACTORS_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_CONTRACTORS_INV,
  CONTRACTORS_RESULTS,
  CONTRACTORS_LOADING,
  FAVORITE_CONTRACTORS_RESULTS,
  FAVORITE_CONTRACTORS_LOADING,
  UNAVAILABLE_CONTRACTORS_RESULTS,
  UNAVAILABLE_CONTRACTORS_LOADING
} from "redux/actions/contractorsActions";

const initialState = {
  timestamp: null,
  loading: true,
  query: "",
  results: [],           // Regular contractors results
  activeResults: [],     // Active contractors results
  inactiveResults: [], 
  contractors:[],
  invoiceInv: [],
  favoriteResults: [],
  unavailableResults:[],
  openInvoiceContractor: [],
  meta: {                // Meta for regular contractors
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
  activeMeta: {          // Meta for active contractors
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
  inactiveMeta: {        // Meta for inactive contractors
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
  favoriteMeta: {        // Meta for favorite contractors
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
  unavailableMeta: {     // Meta for unavailable contractors
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

export default function contractors(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_CONTRACTORS_RESULTS:
      return {
        ...state,
        results: action.results.result,   
        meta: action.results.meta,       
        loading: false,
      };
    case ACTIVE_CONTRACTORS_RESULTS:
      return {
        ...state,
        activeResults: action.results.result, 
        activeMeta: action.results.meta,
        loading: false,
      };
    case CONTRACTORS_RESULTS:
      return {
        ...state,
        contractors: action.results.data, 
        meta: action.results.meta,
        loading: false,
      };
    case INACTIVE_CONTRACTORS_RESULTS:
      return {
        ...state,
        inactiveResults: action.results.result,
        inactiveMeta: action.results.meta,
        loading: false,
      };
    case SEARCH_CONTRACTORS_INV:
      return {
        ...state,
        results: action.results.result, 
        meta: action.results.meta,       
        loading: false,
      };
    case FAVORITE_CONTRACTORS_RESULTS:
      return {
        ...state,
        favoriteResults: action.results.result,
        favoriteMeta: action.results.meta,
        loading: false,
      };
    case UNAVAILABLE_CONTRACTORS_RESULTS:
      return {
        ...state,
        unavailableResults: action.results.result, 
        unavailableMeta: action.results.meta,
        loading: false,
      };
    case CONTRACTORS_LOADING:
    case UNAVAILABLE_CONTRACTORS_LOADING:
    case FAVORITE_CONTRACTORS_LOADING:
    case SEARCH_CONTRACTORS_LOADING:
    case ACTIVE_CONTRACTORS_LOADING:
    case INACTIVE_CONTRACTORS_LOADING:    
      return { ...state, results: [], loading: action.loading };
    case SEARCH_CONTRACTORS:
      return { ...state, results: [], query: action.query, loading: true };
    case CHANGE_PAGE: {
      const { page, contractorType } = action;

      if (contractorType === 'active') {
        return {
          ...state,
          activeMeta: {
            ...state.activeMeta,
            current_page: page,
          },
        };
      } else if (contractorType === 'inactive') {
        return {
          ...state,
          inactiveMeta: {
            ...state.inactiveMeta,
            current_page: page,
          },
        };
      }else if (contractorType === 'favorite') {
        return {
          ...state,
          favoriteMeta: {
            ...state.favoriteMeta,
            current_page: page,
          },
        };
      }else if (contractorType === 'unavailable') {
        return {
          ...state,
          unavailableMeta: {
            ...state.unavailableMeta,
            current_page: page,
          },
        };
      } else {
        return {
          ...state,
          meta: {
            ...state.meta,
            current_page: page,
          },
        };
      }
    }
    case CHANGE_PER_PAGE: {
      const { perPage, contractorType } = action;

      if (contractorType === 'active') {
        return {
          ...state,
          activeMeta: {
            ...state.activeMeta,
            per_page: perPage,
          },
        };
      } else if (contractorType === 'inactive') {
        return {
          ...state,
          inactiveMeta: {
            ...state.inactiveMeta,
            per_page: perPage,
          },
        };
      }else if (contractorType === 'favorite') {
        return {
          ...state,
          favoriteMeta: {
            ...state.favoriteMeta,
            per_page: perPage,
          },
        };
      }else if (contractorType === 'unavailable') {
        return {
          ...state,
          unavailableMeta: {
            ...state.unavailableMeta,
            per_page: perPage,
          },
        };
      } else {
        // Default case: Update regular contractors meta
        return {
          ...state,
          meta: {
            ...state.meta,
            per_page: perPage,
          },
        };
      }
    }
    default:
      return state;
  }
}
