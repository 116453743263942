import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_CONTRACTORS,
  SEARCH_CONTRACTORS_RESULTS,
  ACTIVE_CONTRACTORS_LOADING,
  ACTIVE_CONTRACTORS_RESULTS,
  INACTIVE_CONTRACTORS_LOADING,
  INACTIVE_CONTRACTORS_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  FAVORITE_CONTRACTORS_RESULTS,
  FAVORITE_CONTRACTORS_LOADING,
  CONTRACTORS_LOADING,
  CONTRACTORS_RESULTS,
  UNAVAILABLE_CONTRACTORS_RESULTS,
  UNAVAILABLE_CONTRACTORS_LOADING
} from "redux/actions/contractorsActions";
import { 
  getContractorsRequest, 
  getContractorsInvRequest, 
  downloadContractorRequest, 
  getActiveContractorsRequest, 
  getInactiveContractorsRequest,
  getContractors, 
  getFavoriteContractorsRequest,
  getUnavailableContractorsRequest,
} from "api/contractors";
import debounce from "lodash.debounce";

const useContractors = () => {
  const dispatch = useDispatch();
  const contractorsReducer = useSelector((state) => state.contractors);

  const getAllContractors = (params) => {
    dispatch({ type: CONTRACTORS_LOADING, loading: true });
    return getContractors({ ...params })
      .then((res) => {
        dispatch({ type: CONTRACTORS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (contractorsReducer.loading) {
          dispatch({ type: CONTRACTORS_LOADING, loading: false });
        }
      });
  };

  const getActiveContractors = (params) => {
    // dispatch({ type: ACTIVE_CONTRACTORS_LOADING, loading: true });
    return getActiveContractorsRequest({ ...params })
      .then((res) => {
        dispatch({ type: ACTIVE_CONTRACTORS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (contractorsReducer.loading) {
          dispatch({ type: ACTIVE_CONTRACTORS_LOADING, loading: false });
        }
      });
  };

  const getInactiveContractors = (params) => {
    // dispatch({ type: INACTIVE_CONTRACTORS_LOADING, loading: true });
    return getInactiveContractorsRequest({ ...params })
      .then((res) => {
        dispatch({ type: INACTIVE_CONTRACTORS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (contractorsReducer.loading) {
          dispatch({ type: INACTIVE_CONTRACTORS_LOADING, loading: false });
        }
      });
  };

  const getFavoriteContractorsData = (params) => {
    // dispatch({ type: FAVORITE_CONTRACTORS_LOADING, loading: true });
    return getFavoriteContractorsRequest({ ...params })
      .then((res) => {
        dispatch({ type: FAVORITE_CONTRACTORS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (contractorsReducer.loading) {
          dispatch({ type: FAVORITE_CONTRACTORS_LOADING, loading: false });
        }
      });
  };

  const getUnavailableContractorsData = (params) => {
    return getUnavailableContractorsRequest({ ...params })
      .then((res) => {
        dispatch({ type: UNAVAILABLE_CONTRACTORS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (contractorsReducer.loading) {
          dispatch({ type: UNAVAILABLE_CONTRACTORS_LOADING, loading: false });
        }
      });
  };

  const search = debounce((query) => {
    dispatch({ type: SEARCH_CONTRACTORS, query });
    getContractorsRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_CONTRACTORS_RESULTS, results: res?.data });
    });
  }, 600);

  const searchInv = debounce((query) => {
    getContractorsInvRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_CONTRACTORS_RESULTS, results: res.data });
    });
  }, 600);

  const changePage = (newPage, contractorType) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 , contractorType});
  };

  const changePerPage = (event, contractorType) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value), contractorType });
  };

  const downloadContractorPdf = (ContractorId) => downloadContractorRequest(ContractorId);

  return {
    contractorsReducer,
    search,
    searchInv,
    changePerPage,
    changePage,
    getActiveContractors,
    getInactiveContractors,
    downloadContractorPdf,
    getAllContractors,
    getFavoriteContractorsData,
    getUnavailableContractorsData
  };
};

export default useContractors;
