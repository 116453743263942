import axios from "utils/axios";

const GET_INVOICES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice`;
const UPDATE_INVOICES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice/update`;
const GET_INVOICES_CON = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice`;
const GET_OPEN_INVOICES = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice/open-invoices`;
const GET_OPEN_INVOICE_COLUMN = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice`;
const DELETE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/invoices/canceled-invoices`;
const EDIT_JOB_NUMBER = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice`;
const CANCLE_INVOICE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice/cancel-invoice`;
const GET_ADDRESS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/invoices/property-address`;
const CHANGE_ADDRESS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice/address/update-property`;
const EMAIL_ASSIGNED_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/user/get/csa-name`;
// export const getInvoicesRequest = (params, pagination) => {
//   return axios.post(`${GET_INVOICES_ROUTE}/con-invoices`, {}, {
//     params: { ...params, ...(pagination || {}) },
//   });
// };

export const getInvoicesRequest = (params) => {
  return axios.post(`${GET_INVOICES_ROUTE}/con-invoices`,params)
};

export const editJobNumber = (data) => 
  axios.post(`${EDIT_JOB_NUMBER}/edit-job_num`, {...data}, {
  });

export const updateInvoicedata = (id, data) =>
  axios.post(`${UPDATE_INVOICES_ROUTE}/${id}`, { ...data });

export const getInvoicedata = (id) =>
  axios.post(`${GET_INVOICES_CON}/${id}`  );

export const viewInvoiceRequest = (id) =>
  axios.post(`${GET_INVOICES_ROUTE}/${id}`);

export const emailInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/send-email/${id}`);

export const downloadInvoiceRequest = (id) =>
  axios.get(`${GET_INVOICES_ROUTE}/get-pdf/${id}`, {
    responseType: 'blob',
  });

export const deletedCheckDataRequest = (data) =>
  axios.post(`${GET_INVOICES_ROUTE}/deleted-check`, data);

export const createInvoiceRequest = (params) =>
  axios.post(`${GET_INVOICES_ROUTE}`, params);

export const getOpenInvoiceDataRequest = (data) => axios.post(GET_OPEN_INVOICES, data);

export const getOpenInvoiceContractorRequest = (params) => {
  return axios.post(`${GET_OPEN_INVOICE_COLUMN}/cancel-invoices`,params)
};
export const deleteInvoiceRequest = (id) => axios.post(`${DELETE}/${id}`);

export const cancleInvoiceRequest = (id) =>
  axios.get(`${CANCLE_INVOICE}/${id}`);

export const getAddress = (membership_id) =>
  axios.get(`${GET_ADDRESS_ROUTE}/${membership_id}`);

export const addressChange = (id, mem_street) =>
  axios.post(CHANGE_ADDRESS_ROUTE, { id, mem_street: mem_street });

export const emailAssignedTo = () =>
  axios.get(`${EMAIL_ASSIGNED_ROUTE}`);