import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Box,
  Tooltip,
  Tab
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useApplication from "hooks/useApplication";
import useContractors from "hooks/useContractors";
import SearchInput from "components/SearchInput";
import ContractorBlocks from "components/ContractorBlocks";
import ".././../../../utils/sweetalert.css";
import useAccessControl from "hooks/useAccessControl";
import ContractorData from "./components/ContractorData";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export default function Contractors() {
  const { setPage } = useApplication();
  const { canCreateContractor } = useAccessControl()
  const [tabValue, setTabValue] = useState("0");
  const [activeLoaded, setActiveLoaded] = useState(false);
  const [inactiveLoaded, setInactiveLoaded] = useState(false);
  const [favoritesLoaded, setFavoritesLoaded] = useState(false);
  const [unavailableLoaded, setUnavailableLoaded] = useState(false);

  const {
    search,
    contractorsReducer: { query, loading, meta },
    getActiveContractors,
    getInactiveContractors,
    getFavoriteContractorsData,
    getUnavailableContractorsData
  } = useContractors();
  const navigate = useNavigate();

  const handleSearch = (search) => {
    getActiveContractors({
      search,
      per_page: meta?.per_page,
    });
    getInactiveContractors({
      search,
      per_page: meta?.per_page,
    });
    getFavoriteContractorsData({
      search,
      per_page: meta?.per_page,
    });
    getUnavailableContractorsData({
      search,
      per_page: meta?.per_page,
    });
  };

  useEffect(() => {
    setPage({
      title: "Contractors",
      path: "/app/contractors",
    });
    search(query);
    getActiveContractors();
    getInactiveContractors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabValue === "0" && !activeLoaded) {
      getActiveContractors(query);
      setActiveLoaded(true);
    } else if (tabValue === "1" && !inactiveLoaded) {
      getInactiveContractors(query);
      setInactiveLoaded(true);
    } else if (tabValue === "2" && !unavailableLoaded) {
      getUnavailableContractorsData();
      setUnavailableLoaded(true);
    } else if (tabValue === "3" && !favoritesLoaded) {
      getFavoriteContractorsData();
      setFavoritesLoaded(true);
    }
  }, [tabValue, query, activeLoaded, inactiveLoaded, favoritesLoaded, unavailableLoaded, getActiveContractors, getInactiveContractors, getFavoriteContractorsData, getUnavailableContractorsData]);

  return (
    <ContractorBlocks
      title="Contractors"
      loading={loading}
      actions={
        <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">

          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />

          {canCreateContractor() &&
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: 'auto' }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/contractors/create")}
            >
              Add contractor
            </Button>
          }
          <Tooltip title="Import contractor">
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: 'auto', width: "28%" }}
              variant="contained"
              onClick={() => navigate("/app/importContractor")}
            >
              <AddIcon style={{ fontSize: "20px", marginRight: "6px" }} />
              Import Con. Inv 
            </Button>
          </Tooltip>
        </Box>
      }
    >
     <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Active Contractors"
                value="0"
              />
               <Tab
                label="Inactive Contractors"
                value="1"
              />
              <Tab
                label="Unavailable Contractors"
                value="2"
              />
               <Tab
                label="Favorite Contractors"
                value="3"
              />
            </TabList>
          </Box>
          <TabPanel value="0">
            <ContractorData status="active" />
          </TabPanel>
          <TabPanel value="1">
           <ContractorData status="inactive"/>
          </TabPanel>
          <TabPanel value="2">
           <ContractorData status="unavailable"/>
          </TabPanel>
          <TabPanel value="3">
           <ContractorData status="favorite"/>
          </TabPanel>
        </TabContext>
      </Box>
    </ContractorBlocks>
  );
}
