import axios from "utils/axios";
import { SEARCH_CONTRACTOR_ROUTE } from "./routes";


const GET_CONTRACTOR_BY_EMAIL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/search-by-email`;
const CREATE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const STATUS_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/change-status`;
const GET_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const UPDATE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const POST_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/import-contractor`;
const POST_CONTRACTOR_INV = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/invoice`;
const GET_CONTRACTOR_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractor-invoice/update-status`;
const GET_ACTIVE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/active-contractors`;
const GET_INACTIVE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/inactive-contractors`;
const GET_FAVORITE_CONTRACTOR_REQUEST = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/favorite/contractors`;
const GET_FAVORITE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/add-to-fav`;
const GET_UNAVAILABLE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/unavailable/contractors`;
const GET_REMOVE_FAVORITE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/remove/favorite`;

export const searchContractorsRequest = (search, limit = 10) =>
  axios.get(SEARCH_CONTRACTOR_ROUTE, { params: { search, limit } });

export const getActiveContractorsRequest = (params) =>
  axios.get(GET_ACTIVE_CONTRACTOR, {params});

export const getInactiveContractorsRequest = (params) =>
  axios.get(GET_INACTIVE_CONTRACTOR, { params });

export const getFavoriteContractorsRequest = (params) =>
  axios.get(GET_FAVORITE_CONTRACTOR_REQUEST, { params });

export const getUnavailableContractorsRequest = (params) =>
  axios.get(GET_UNAVAILABLE_CONTRACTOR, { params });

export const getContractorsRequest = (search) =>
  axios.get(GET_ACTIVE_CONTRACTOR, { params: search });

export const getContractorByEmail = (email) =>
  axios.post(GET_CONTRACTOR_BY_EMAIL, { email });

export const createContractor = (data) =>
  axios.post(CREATE_CONTRACTOR, { ...data });

export const statusContractor = (id, payload) =>
  axios.get(`${STATUS_CONTRACTOR}/${id}`, { params: payload });

// export const getContractor = (id) => axios.get(`${GET_CONTRACTOR}`);
export const getContractor = (id) => axios.get(`${GET_CONTRACTOR}/${id}`);

export const getContractors = (params) => axios.get(`${GET_CONTRACTOR}`, {params});

export const getContractorsInvRequest = (id, conid) =>// /id?id=23

  axios.post(`${POST_CONTRACTOR_INV}/id`, {
    con_inv_id: conid || "",
    contractor_id: id || ""
  })

export const updateContractor = (id, data) =>
  axios.post(`${UPDATE_CONTRACTOR}/${id}`, { ...data });

export const csvUploadContractor = (data) =>
  axios.post(POST_CONTRACTOR, data, {
    headers: { 'Content-Type': 'application/json' }
  });

export const downloadContractorRequest = (id) =>
  axios.post(`${CREATE_CONTRACTOR}/download-con-inv/${id}`, {
    responseType: 'blob',
  });

export const changeStatus = (id, newStatus) =>
  axios.post(GET_CONTRACTOR_ROUTE, { id, status: newStatus });

export const getFavoriteContractor = (id) =>
  axios.get(`${GET_FAVORITE_CONTRACTOR}/${id}`);

export const getRemoveFavoriteContractor = (id) =>
  axios.get(`${GET_REMOVE_FAVORITE_CONTRACTOR}/${id}`);