import { changeTaskUserRequest, getEmailRequest } from "api/Email";
import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_EMAIL_LOADING,
  CHANGE_PER_PAGE,
  CHANGE_PAGE,
  SEARCH_EMAIL_RESULTS,
  CHANGE_TASK_USER,
} from "redux/actions/emailAction";

const useEmail = () => {
  const dispatch = useDispatch();
  const emailReducer = useSelector((state) => state.email);

  const getAllEmail = (params) => {
    dispatch({ type: SEARCH_EMAIL_LOADING, loading: true });
    return getEmailRequest({ ...params })
      .then((res) => {
        dispatch({ type: SEARCH_EMAIL_RESULTS, results: res.data });
      })
      .finally(() => {
        if (emailReducer?.loading) {
          dispatch({ type: SEARCH_EMAIL_LOADING, loading: false });
        }
      });
  };

  const changeTaskUser = (task_id, user_id, module, name) => {
    dispatch({ type: CHANGE_TASK_USER, id: task_id, user_id, module, name });
    changeTaskUserRequest({ id: task_id, user_id, module, name });
  };

  const changePage = (newPage, emailType) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1, emailType });
  };

  const changePerPage = (event, emailType) => {
    dispatch({
      type: CHANGE_PER_PAGE,
      perPage: parseInt(event.target.value),
      emailType,
    });
  };

  return {
    emailReducer,
    changePerPage,
    changePage,
    getAllEmail,
    changeTaskUser,
  };
};

export default useEmail;
